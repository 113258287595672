import Css from "../../style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { FormElement } from "nlib/common/Form";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Checkbox from "nlib/ui/Checkbox";
import Countries from "const/Countries";
import FormLabel from "nlib/common/FormLabel";
import IntegrationServices from "const/IntegrationServices";
import React, { useMemo } from "react";

const ClientPortalTab = ({ integrationService }) => {
  const { uiTexts } = useSelector(getTextsData);

  const { countryCode } = useSelector(getActiveOrganization);

  const usCountry = countryCode === Countries.US;

  const { xeroBusiness, quickBooksBusiness, zohoBusiness } = useMemo(() => {
    const serviceData = IntegrationServices.getByValue(integrationService);

    return {
      IconComponent: serviceData && serviceData.logo
        ? () => <img className={Css.logo} src={serviceData.logo} /> : Icons.Briefcase,
      xeroBusiness: integrationService === IntegrationServices.XERO.value,
      quickBooksBusiness: integrationService === IntegrationServices.QUICK_BOOKS.value,
      zohoBusiness: integrationService === IntegrationServices.ZOHO.value
    };
  }, [integrationService]);

  return (
    <div className={Css.block}>
      <FormLabel>{uiTexts.clientView}</FormLabel>
      <div className={Css.row}>
        <FormElement toggle name="allowClientPayeeSelection" element={Checkbox}>
          <span>{uiTexts.allowPayeeSelection}</span>
        </FormElement>
      </div>
      {quickBooksBusiness && (
        <div className={Css.row}>
          <FormElement toggle name="allowClientLocationSelection" element={Checkbox}>
            <span>{uiTexts.allowLocationSelection}</span>
          </FormElement>
        </div>
      )}
      {zohoBusiness && (
        <div className={Css.row}>
          <FormElement toggle name="allowClientClassSelection" element={Checkbox}>
            <span>{uiTexts.allowClassSelection}</span>
          </FormElement>
        </div>
      )}
      <div className={Css.row}>
        <FormElement toggle name="allowClientCategorySelection" element={Checkbox}>
          <span>{uiTexts.allowCategorySelection}</span>
        </FormElement>
      </div>
      {(quickBooksBusiness || zohoBusiness) && (
        <div className={Css.row}>
          <FormElement toggle name="allowClientProjectSelection" element={Checkbox}>
            <span>{uiTexts.allowProjectSelection}</span>
          </FormElement>
        </div>
      )}
      {(quickBooksBusiness || xeroBusiness) && (
        <div className={Css.row}>
          <FormElement toggle name="allowClientClassSelection" element={Checkbox}>
            <span>{uiTexts.allowClassSelection}</span>
          </FormElement>
        </div>
      )}
      {!usCountry && (
        <div className={Css.row}>
          <FormElement toggle name="allowClientTaxRateSelection" element={Checkbox}>
            <span>{uiTexts.allowTaxRateSelection}</span>
          </FormElement>
        </div>
      )}
    </div>
  );
};

export default React.memo(ClientPortalTab);
