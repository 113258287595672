
import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Checkbox } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Input from "nlib/ui/Input";
import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";

const IncludeExclude = (props) => {
  const {
    emptyPlaceholder,
    disabled,
    value = [],
    options = [],
    onChange,
    className,
    initialAddedSelected = [],
    ...restProps
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const [availableSelected, setAvailableSelected] = useState([]);

  const [addedSelected, setAddedSelected] = useState(initialAddedSelected);

  const [search, setSearch] = useState("");

  const filteredItems = useMemo(() => {
    const str = search.trim().toLocaleLowerCase();

    if (!str) return options;

    return options.filter((option) => option.label.toLocaleLowerCase().includes(str));
  }, [options, search]);

  const availableFilteredItems = useMemo(() => {
    return filteredItems.filter((option) => !value.includes(option.value));
  }, [filteredItems, value]);

  const selectableAvailableItems = useMemo(() => {
    return availableFilteredItems.filter((option) => !option.disabled);
  }, [availableFilteredItems]);

  const addedFilteredItems = useMemo(() => {
    return filteredItems.filter((option) => value.includes(option.value));
  }, [filteredItems, value]);

  const selectableAddedItems = useMemo(() => {
    return addedFilteredItems.filter((option) => !option.disabled);
  }, [addedFilteredItems]);

  const handleCheckboxChange = useCallback((checked, { currentTarget: { dataset } }) => {
    if (value.includes(dataset.value)) {
      setAddedSelected((prev) => checked ? [...prev, dataset.value] : prev.filter((item) => item !== dataset.value));
    } else {
      setAvailableSelected((prev) => checked ? [...prev, dataset.value] : prev.filter((item) => item !== dataset.value));
    }
  }, [value]);

  const handleAddSelectedClick = useCallback(() => {
    onChange([...value, ...availableSelected]);
    setAvailableSelected([]);
  }, [availableSelected, onChange, value]);

  const handleRemoveSelectedClick = useCallback(() => {
    onChange(value.filter((item) => !addedSelected.includes(item)));
    setAddedSelected([]);
  }, [addedSelected, onChange, value]);

  const handleSelectAllAvailable = useCallback((checked) => {
    setAvailableSelected(checked ? selectableAvailableItems.map((option) => option.value) : []);
  }, [selectableAvailableItems]);

  const handleSelectAllAdded = useCallback((checked) => {
    setAddedSelected(checked ? selectableAddedItems.map((option) => option.value) : []);
  }, [selectableAddedItems]);

  return (
    <div {...restProps} className={classNames(Css.includeExclude, className)}>
      <Input
        value={search}
        disabled={disabled}
        className={Css.input}
        placeholder={uiTexts.search}
        iconBefore={Icons.MagnifyingGlass}
        onChange={setSearch} />
      <div className={Css.row}>
        <div className={Css.col}>
          <div className={Css.head}>
            <Checkbox
              disabled={disabled}
              checked={availableFilteredItems.length
                && availableFilteredItems.every((item) => availableSelected.includes(item.value))}
              onChange={handleSelectAllAvailable}>{`${uiTexts.available} (${availableFilteredItems.length})`}</Checkbox>
            <div
              className={Css.action}
              disabled={disabled || !availableSelected.length}
              onClick={handleAddSelectedClick}>{uiTexts.addSelected}</div>
          </div>
          <div className={Css.content}>
            {availableFilteredItems.length
              ? availableFilteredItems.map((option) => {
                return (
                  <div key={option.value} className={Css.item} title={option.label}>
                    <Checkbox
                      title={option.label}
                      disabled={disabled || option.disabled}
                      data-value={option.value}
                      checked={availableSelected.includes(option.value)}
                      className={Css.checkbox}
                      labelClassName={Css.label}
                      onChange={handleCheckboxChange}>
                      {option.label}
                    </Checkbox>
                  </div>
                );
              })
              : (!!search.trim() && !!emptyPlaceholder && (
                <div className={Css.placeholder}>{emptyPlaceholder}</div>
              ))}
          </div>
        </div>
        <div className={Css.col}>
          <div className={Css.head}>
            <Checkbox
              disabled={disabled}
              checked={addedFilteredItems.length
                && addedFilteredItems.every((item) => addedSelected.includes(item.value))}
              onChange={handleSelectAllAdded}>{`${uiTexts.added} (${addedFilteredItems.length})`}</Checkbox>
            <div
              className={Css.action}
              disabled={disabled || !addedSelected.length}
              onClick={handleRemoveSelectedClick}>{uiTexts.removeSelected}</div>
          </div>
          <div className={Css.content}>
            {addedFilteredItems.length
              ? addedFilteredItems.map((option) => {
                return (
                  <div key={option.value} className={Css.item}>
                    <Checkbox
                      title={option.label}
                      disabled={disabled || option.disabled}
                      data-value={option.value}
                      checked={addedSelected.includes(option.value)}
                      className={Css.checkbox}
                      labelClassName={Css.label}
                      onChange={handleCheckboxChange}>
                      {option.label}
                    </Checkbox>
                  </div>
                );
              })
              : (!!search.trim() && !!emptyPlaceholder && (
                <div className={Css.placeholder}>{emptyPlaceholder}</div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(IncludeExclude);
