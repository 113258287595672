import { useCallback } from "react";
import { useSelector } from "react-redux";

import { getActiveOrganization } from "selectors/organizations";
import Features from "const/Features";

const useAllowedFeature = (propFeature) => {
  const activeOrganization = useSelector(getActiveOrganization);

  const checkIsAllowed = useCallback((feature) => {
    return Features.checkIsAllowed(activeOrganization.pricingPlan, feature);
  }, [activeOrganization]);

  return propFeature ? checkIsAllowed(propFeature) : checkIsAllowed;
};

export default useAllowedFeature;
