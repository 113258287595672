import * as Yup from "yup";
import { ModalFooter } from "nlib/ui/Modal";
import { checkUsersFetching } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import FormContext from "contexts/FormContext";
import React, { useCallback, useContext, useMemo } from "react";
import RpaFrequencies from "const/RpaFrequencies";
import RpaTransactionTypes from "const/RpaTransactionTypes";
import Utils from "utils/Utils";

const VALIDATION_SCHEMA = Yup.object().shape({
  advancedDocumentsWorkflow: Yup.bool().required(),
  recognizeDocumentLineItems: Yup.bool().required(),
  allowClientPayeeSelection: Yup.bool().required(),
  allowClientCategorySelection: Yup.bool().required(),
  allowClientClassSelection: Yup.bool().required(),
  allowClientLocationSelection: Yup.bool().required(),
  allowClientProjectSelection: Yup.bool().required()
});

const RPA_VALIDATION_SCHEMA = Yup.object().shape({
  autoAskClient: Yup.bool(),
  rpaClientEmail: Yup.string().trim().when("autoAskClient", {
    is: true,
    then: Yup.string().email().trim().required()
  }),
  rpaAutoApprove: Yup.bool(),
  rpaExcludedAccountsToggle: Yup.bool(),
  rpaExcludedAccounts: Yup.array().when("rpaExcludedAccountsToggle", {
    is: false,
    then: Yup.array().of(Yup.string().trim()).min(1).required()
  }),
  rpaStartFrom: Yup.string().trim().required(),
  rpaTransactionTypes: Yup.string().oneOf(Object.values(RpaTransactionTypes)).required(),
  rpaFrequency: Yup.string().oneOf([...Object.values(RpaFrequencies), ""]),
  rpaPaused: Yup.bool()
});

const Buttons = ({ disabled, initialValues, rpaMode, onSubmit, onCancel }) => {
  const { uiTexts } = useSelector(getTextsData);

  const fetchingUsers = useSelector(checkUsersFetching);

  const { values } = useContext(FormContext);

  const formDataValid = useMemo(() => {
    return VALIDATION_SCHEMA.isValidSync(values) && (!rpaMode || RPA_VALIDATION_SCHEMA.isValidSync(values));
  }, [values, rpaMode]);

  const formHasChanges = useMemo(() => {
    return !Utils.checkDeepEquality(initialValues, values);
  }, [initialValues, values]);

  const handleCancelClick = useCallback(() => {
    onCancel();
  }, [onCancel]);

  return (
    <ModalFooter>
      <Button
        primary large
        disabled={disabled || !formDataValid || !formHasChanges}
        onClick={onSubmit}>
        {uiTexts.save}
      </Button>
      <Button
        large outline
        disabled={fetchingUsers}
        onClick={handleCancelClick}>
        {uiTexts.cancel}
      </Button>
    </ModalFooter>
  );
};

export default React.memo(Buttons);
