import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Input } from "nlib/ui";
import { checkIsBusinessUser, getUserData, getUserRestrictions } from "selectors/user";
import { getActiveOrganization, getOrganizationsData } from "selectors/organizations";
import { getLanguage, getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AuthZeroActions from "actions/AuthZeroActions";
import Avatar from "nlib/ui/Avatar";
import Constants from "const/Constants";
import DropDown from "nlib/ui/DropDown";
import DropDownCaret from "nlib/ui/DropDown/lib/DropDownCaret";
import DropDownContent from "nlib/ui/DropDown/lib/DropDownContent";
import DropDownMenuDivider from "nlib/ui/DropDown/lib/DropDownMenuDivider";
import DropDownMenuItem from "nlib/ui/DropDown/lib/DropDownMenuItem";
import DropDownToggle from "nlib/ui/DropDown/lib/DropDownToggle";
import OrganizationsActions from "actions/OrganizationsActions";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import StripeActions from "actions/StripeActions";
import SuperAdminOrganizationInfoBadge from "./lib/SuperAdminOrganizationInfoBadge";
import TextsActions from "actions/TextsActions";
import UiActions from "actions/UiActions";
import UiRoutes from "const/UiRoutes";
import UserSettingsWindow from "./lib/UserSettingsWindow";
import classNames from "classnames";
import useShowCommonModal from "hooks/useShowCommonModal";

const getSearchFilterFunction = (searchString) => (organizationData) => {
  const { name, countryCode } = organizationData;

  return name.toLowerCase().includes(searchString)
    || countryCode.toLowerCase().includes(searchString);
};

const SettingsDropDown = ({ disabled, className, mobile }) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const showCommonModal = useShowCommonModal();

  const { holdUser, fullName, guestUser, superAdmin } = useSelector(getUserData);

  const organizationsData = useSelector(getOrganizationsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const businessUser = useSelector(checkIsBusinessUser);

  const language = useSelector(getLanguage);

  const userRestrictions = useSelector(getUserRestrictions);

  const [editUserSettingsWindowOpened, setEditUserSettingsWindowOpened] = useState(false);

  const [organizationSwitcherShown, setOrganizationSwitcherShown] = useState(false);

  const [languageSwitcherShown, setLanguageSwitcherShown] = useState(false);

  const [opened, setOpened] = useState(false);

  const [searchString, setSearchString] = useState("");

  const userOrganizations = useMemo(() => {
    return organizationsData ? organizationsData.filter(({ superAdminAccess }) => !superAdminAccess) : [];
  }, [organizationsData]);

  const superAdminOrganizations = useMemo(() => {
    return organizationsData ? organizationsData.filter(({ superAdminAccess }) => superAdminAccess) : [];
  }, [organizationsData]);

  const [userOrganizationsFiltered, superAdminOrganizationsFiltered] = useMemo(() => {
    const searchStringNormalized = searchString.trim().toLowerCase();

    if (!searchStringNormalized) return [userOrganizations, superAdminOrganizations];

    const filterFunction = getSearchFilterFunction(searchStringNormalized);

    return [
      userOrganizations.filter(filterFunction),
      superAdminOrganizations.filter(filterFunction)
    ];
  }, [userOrganizations, superAdminOrganizations, searchString]);

  const superAdminActiveOrganizations = superAdminOrganizationsFiltered.filter(({ inactive }) => !inactive);

  const superAdminInactiveOrganizations = superAdminOrganizationsFiltered.filter(({ inactive }) => inactive);

  const billingLocked = (activeOrganization.billingId && !activeOrganization.subscriptionId) || holdUser;

  const currentLanguageName = useMemo(() => {
    if (!language) return "";

    return Constants.LANGUAGES[language.toUpperCase()]?.name || "";
  }, [language]);

  const setDropdownOpened = useCallback((state) => {
    setOrganizationSwitcherShown(false);
    setOpened(state);
  }, []);

  const handleLogoutClick = useCallback(async() => {
    const modalResult = await showCommonModal({
      text: messages.logoutConfirm,
      confirm: true
    });

    if (modalResult) {
      dispatch(AuthZeroActions.logoutUser());
    }
  }, [messages, dispatch, showCommonModal]);

  const handleProfileClick = useCallback(() => {
    setEditUserSettingsWindowOpened(true);
  }, []);

  const handleCloseUserSettingsWindow = useCallback(() => {
    setEditUserSettingsWindowOpened(false);
  }, []);

  const handleOrganizationsClick = useCallback(() => {
    setOrganizationSwitcherShown(true);
  }, []);

  const handleLanguagesClick = useCallback(() => {
    setLanguageSwitcherShown(true);
  }, []);

  const handleBackButtonClick = useCallback(() => {
    setLanguageSwitcherShown(false);
    setOrganizationSwitcherShown(false);
  }, []);

  const handleOrganizationSettingsClick = useCallback(() => {
    history.push(UiRoutes.SETTINGS);
  }, [history]);

  const handleLanguageClick = useCallback(async(code) => {
    setLanguageSwitcherShown(false);
    dispatch(UiActions.togglePreloader(true));
    await dispatch(TextsActions.setAppLanguage(code));
    dispatch(UiActions.togglePreloader(false));
  }, [dispatch]);

  const handleBillingClick = useCallback(async() => {
    let sessionUrl = null;

    if (activeOrganization.billingId && activeOrganization.subscriptionId) {
      sessionUrl = await dispatch(StripeActions.createBillingPortalSession());
    } else {
      sessionUrl = await dispatch(StripeActions.createSetupCheckoutSession());
    }

    window.location.href = sessionUrl;
  }, [activeOrganization.billingId, activeOrganization.subscriptionId, dispatch]);

  const handleOrganizationClick = useCallback(async(organizationId) => {
    if (organizationId === activeOrganization.id) return;
    dispatch(UiActions.togglePreloader(true));

    const result = await dispatch(OrganizationsActions.changeActiveOrganization(organizationId));

    if (result) {
      window.location.href = UiRoutes.MAIN;
    } else {
      dispatch(UiActions.togglePreloader(false));
    }
  }, [activeOrganization.id, dispatch]);

  const handleSearchOrgChange = useCallback((value) => {
    setSearchString(value);
  }, []);

  const handleDropdownToggle = useCallback((state) => {
    setDropdownOpened(state);
    setOrganizationSwitcherShown(false);
    setLanguageSwitcherShown(false);
  }, [setDropdownOpened]);

  const handleDeleteAllInactiveOrganizationsClick = useCallback(async() => {
    // eslint-disable-next-line
      const promptResult = prompt(
      [
        `[ DELETE ${superAdminInactiveOrganizations.length} INACTIVE ORGANIZATIONS ]`,
        "To confirm, type exactly: DELETE ALL INACTIVE ORGANIZATIONS"
      ].join("\n\n")
    );

    if (promptResult === "DELETE ALL INACTIVE ORGANIZATIONS") {
      dispatch(UiActions.togglePreloader(true));

      const successful = await dispatch(OrganizationsActions.deleteAllInactiveOrganizations());

      if (successful) {
        await showCommonModal({
          text: `${superAdminInactiveOrganizations.length} organizations have been scheduled for deletion`
        });
      }
      dispatch(UiActions.togglePreloader(false));
    }
  }, [dispatch, showCommonModal, superAdminInactiveOrganizations.length]);

  return (
    <>
      <DropDown
        disabled={disabled}
        opened={opened}
        className={classNames(Css.settingsDropDown, className)}
        setOpened={handleDropdownToggle}>
        <DropDownToggle className={Css.toggle}>
          <div className={Css.avatarContainer}>
            <Avatar className={Css.avatar} title={fullName} />
          </div>
          <DropDownCaret />
        </DropDownToggle>
        <DropDownContent
          alignRight
          modal={mobile}
          title={mobile && (
            organizationSwitcherShown || languageSwitcherShown
              ? (
                <div className={Css.backButton} onClick={handleBackButtonClick}>
                  <Icons.ArrowLeft />
                  <span>{uiTexts.back}</span>
                </div>
              )
              : fullName)}
          className={classNames(
            Css.dropdownContent,
            organizationSwitcherShown && Css.organizationSwitcherShown,
            languageSwitcherShown && Css.languageSwitcherShown,
            superAdminOrganizations.length && Css.superAdmin
          )}>
          <div className={Css.mainContent}>
            {!mobile && (
              <DropDownMenuItem
                title={superAdmin ? "You are a super admin, Harry!" : fullName}
                closeOnClick={false}>
                <div className={Css.name}>
                  {superAdmin && <Icons.Star
                    weight="fill"
                    className={CommonCss.warningText} />}
                  <span>{fullName}</span>
                </div>
              </DropDownMenuItem>
            )}
            {!guestUser && (
              <>
                <DropDownMenuItem onClick={handleProfileClick}>
                  <Icons.User /><span>{uiTexts.editUserProfile}</span>
                </DropDownMenuItem>
              </>
            )}
            {!activeOrganization.customBilling && !userRestrictions.usageStatsRead && (
              <DropDownMenuItem onClick={handleBillingClick}>
                <Icons.CurrencyDollar />
                <span>
                  {activeOrganization.billingId && activeOrganization.subscriptionId ? uiTexts.billing : uiTexts.setupBilling}
                </span>
              </DropDownMenuItem>
            )}
            {!mobile && !businessUser && (!billingLocked || superAdmin) && (
              <DropDownMenuItem onClick={handleOrganizationSettingsClick}>
                <Icons.Gear /><span>{uiTexts.organizationSettings}</span>
              </DropDownMenuItem>
            )}
            <DropDownMenuItem
              closeOnClick={false}
              onClick={handleOrganizationsClick}>
              <Icons.Buildings />
              <div
                className={Css.organizationLabel}
                title={activeOrganization.name}>
                {activeOrganization.name}
              </div>
              <Icons.CaretRight />
            </DropDownMenuItem>
            {mobile && (
              <DropDownMenuItem
                closeOnClick={false}
                onClick={handleLanguagesClick}>
                <Icons.Translate />
                <div
                  className={Css.organizationLabel}
                  title={`${uiTexts.language}: ${currentLanguageName}`}>
                  {`${uiTexts.language}: ${currentLanguageName}`}
                </div>
                <Icons.CaretRight />
              </DropDownMenuItem>
            )}
            <DropDownMenuItem
              className={CommonCss.negativeText}
              onClick={handleLogoutClick}>
              <Icons.SignOut />
              <span>{uiTexts.logout}</span>
            </DropDownMenuItem>
          </div>
          {languageSwitcherShown && (
            <div className={Css.languages}>
              {!mobile && (
                <DropDownMenuItem closeOnClick={false} onClick={handleBackButtonClick}>
                  <Icons.ArrowLeft /> <span>{uiTexts.back}</span>
                </DropDownMenuItem>
              )}
              {Constants.LANGUAGES_ARRAY.map(({ code, name }) => {
                const active = language === code;

                return (
                  <DropDownMenuItem
                    key={code}
                    value={code}
                    className={classNames(Css.menuItem, active && Css.active)}
                    onClick={active ? undefined : handleLanguageClick}>
                    <div title={name}>{name}</div>
                    {active && <Icons.Check weight="bold" />}
                  </DropDownMenuItem>
                );
              })}
            </div>
          )}
          {organizationSwitcherShown && (
            <div className={Css.organizations}>
              {!mobile && (
                <DropDownMenuItem closeOnClick={false} onClick={handleBackButtonClick}>
                  <Icons.ArrowLeft /> <span>{uiTexts.back}</span>
                </DropDownMenuItem>
              )}
              {!!superAdminOrganizations.length && (
                <>
                  <Input
                    autoFocus
                    className={Css.search}
                    iconBefore={Icons.MagnifyingGlass}
                    placeholder="Search by name or country code"
                    value={searchString}
                    onChange={handleSearchOrgChange} />
                  {!!searchString.trim() && !userOrganizationsFiltered.length
                  && !superAdminOrganizationsFiltered.length && (
                    <div className={Css.nothingFound}>Nothing found</div>
                  )}
                </>
              )}
              {userOrganizationsFiltered.map(({
                id,
                name,
                countryCode,
                businessesCount,
                lastActiveAt,
                billingId,
                subscriptionId,
                customBilling
              }) => {
                const selected = activeOrganization.id === id;

                return (
                  <DropDownMenuItem
                    key={id}
                    value={id}
                    className={classNames(Css.menuItem, selected && Css.selected)}
                    onClick={handleOrganizationClick}>
                    <div title={name}>{name}</div>
                    {selected && <Icons.Check weight="bold" />}
                    {!!superAdmin && <SuperAdminOrganizationInfoBadge
                      name={name}
                      countryCode={countryCode}
                      businessesCount={businessesCount}
                      lastActiveAt={lastActiveAt}
                      billingId={billingId}
                      subscriptionId={subscriptionId}
                      customBilling={customBilling} />}
                  </DropDownMenuItem>
                );
              })}
              {!!superAdminOrganizationsFiltered.length && (
                [
                  superAdminActiveOrganizations.length && { value: superAdminActiveOrganizations, active: true },
                  superAdminInactiveOrganizations.length && { value: superAdminInactiveOrganizations, active: false }
                ].filter(Boolean).map(({ value: organizations, active }) => {
                  return (
                    <Fragment key={active}>
                      <DropDownMenuDivider
                        label={`God mode: ${active ? "Active" : "Inactive"} organizations [${organizations.length}]`} />
                      {organizations.map(({
                        id,
                        name,
                        countryCode,
                        businessesCount,
                        lastActiveAt,
                        billingId,
                        subscriptionId,
                        customBilling
                      }) => {
                        const selected = activeOrganization.id === id;

                        return (
                          <DropDownMenuItem
                            key={id}
                            value={id}
                            className={classNames(Css.menuItem, selected && Css.selected)}
                            onClick={selected ? undefined : handleOrganizationClick}>
                            <div title={name}>{name}</div>
                            {selected && <Icons.Check weight="bold" />}
                            <SuperAdminOrganizationInfoBadge
                              name={name}
                              countryCode={countryCode}
                              businessesCount={businessesCount}
                              lastActiveAt={lastActiveAt}
                              billingId={billingId}
                              subscriptionId={subscriptionId}
                              customBilling={customBilling} />
                          </DropDownMenuItem>
                        );
                      })}
                      {!!superAdminInactiveOrganizations.length && !active && <>
                        <DropDownMenuDivider />
                        <Button
                          large danger
                          icon={Icons.TrashSimple}
                          className={Css.deleteAllInactiveButton}
                          onClick={handleDeleteAllInactiveOrganizationsClick}>
                          {`Delete ${superAdminInactiveOrganizations.length} inactive organizations`}
                        </Button>
                      </>}
                    </Fragment>
                  );
                })
              )}
            </div>
          )}
        </DropDownContent>
      </DropDown>
      {editUserSettingsWindowOpened && (
        <UserSettingsWindow onClose={handleCloseUserSettingsWindow} />
      )}
    </>
  );
};

export default React.memo(SettingsDropDown);
