import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button } from "nlib/ui";
import { SplashScreen } from "@capacitor/splash-screen";
import { getAuthErrorDescription } from "selectors/user";
import { getEnvVars } from "selectors/envVars";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import AuthZeroActions from "actions/AuthZeroActions";
import Constants from "const/Constants";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import UserActions from "actions/UserActions";
import Utils from "utils/Utils";
import classNames from "classnames";

const VERIFY_EMAIL_ERROR_CODE = "verify_email";

const AuthHandler = () => {
  const dispatch = useDispatch();

  const { uiTexts, messages, errors } = useSelector(getTextsData);

  const envVars = useSelector(getEnvVars);

  const errorDescription = useSelector(getAuthErrorDescription);

  const [{ disableVerifyEmailButton }, setState] = useState({});

  const error = useMemo(() => {
    try {
      return JSON.parse(atob(errorDescription));
    } catch (exception) {
      return { code: errorDescription };
    }
  }, [errorDescription]);

  const { code: errorCode, payload: errorPayload, payload } = error || {};

  const verifyEmailError = errorCode === VERIFY_EMAIL_ERROR_CODE;

  const handleVerifyEmailButtonClick = useCallback(() => {
    dispatch(UserActions.verifyEmail(payload?.userId));

    setState((prev) => ({ ...prev, disableVerifyEmailButton: true }));
  }, [dispatch, payload]);

  const handleLogoutButtonClick = useCallback(() => {
    dispatch(AuthZeroActions.logoutUser());
  }, [dispatch]);

  useEffect(() => {
    (async() => {
      document.body.dataset.auth = "";
      if (Utils.checkIsNativePlatform()) {
        try {
          await SplashScreen.hide();
        } catch (exception) {}
      }
    })();

    return () => {
      delete document.body.dataset.auth;
    };
  }, [dispatch, envVars, errorDescription]);

  useEffect(() => {
    let checkLoginTimeoutId = null;

    if (errorDescription) {
      checkLoginTimeoutId = setTimeout(
        () => dispatch(AuthZeroActions.loginWithRedirect()),
        Constants.LOGIN_PAGE_REFRESH_TIMEOUT
      );
    } else dispatch(AuthZeroActions.loginWithRedirect(envVars));

    return () => {
      clearInterval(checkLoginTimeoutId);
    };
  });

  return (
    <div className={classNames(Css.authHandler, CommonCss.flexCenter)}>
      <div className={Css.content}>
        {errorCode
          ? <>
            <div className={Css.iconContainer}>
              {verifyEmailError
                ? <>
                  <Icons.EnvelopeOpen weight="thin" className={classNames(Css.icon, Css.semiTransparent)} />
                  <Icons.SealCheck weight="fill" className={classNames(Css.extraIcon, Css.positive)} />
                </>
                : <Icons.Warning weight="thin" className={classNames(Css.icon, Css.warning, Css.semiTransparent)} />}
            </div>
            <div className={Css.title}>
              {verifyEmailError ? messages.verifyEmail : errors.unknown}
            </div>
            <div className={Css.description}>
              {verifyEmailError && errorPayload
                ? Utils.replaceTextVars(messages.verifyEmailDescription, { email: errorPayload.email })
                : errorCode}
            </div>
            <div className={Css.buttonContainer}>
              {verifyEmailError && errorPayload && (
                <Button large dark outline disabled={disableVerifyEmailButton} onClick={handleVerifyEmailButtonClick}>
                  {uiTexts.resendVerificationEmail}
                </Button>
              )}
              <Button large onClick={handleLogoutButtonClick}>
                {uiTexts.logout}
              </Button>
            </div>
          </>
          : <div className={Css.title}>{uiTexts.redirectingPleaseWait}</div>}
      </div>
    </div>
  );
};

export default AuthHandler;
