import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import iconAiRobot from "./icons/aiRobot.svg";
import iconCalculator from "./icons/calculator.svg";
import iconChartLineUp from "./icons/chartLineUp.svg";
import iconChats from "./icons/chats.svg";
import iconChatsCircle from "./icons/chatsCircle.svg";
import iconCirclesFour from "./icons/circlesFour.svg";
import iconClockCounterClockwise from "./icons/clockCounterClockwise.svg";
import iconCpu from "./icons/cpu.svg";
import iconDatabase from "./icons/database.svg";
import iconFileSearch from "./icons/fileSearch.svg";
import iconRepeat from "./icons/repeat.svg";

import * as Icons from "@phosphor-icons/react";
import { Button } from "nlib/ui";
import { getActiveOrganization } from "selectors/organizations";
import { getPricesData } from "selectors/metaData";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Constants from "const/Constants";
import IntegrationServices from "const/IntegrationServices";
import React, { useCallback, useMemo, useState } from "react";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import classNames from "classnames";

const AddNewBusiness = ({ onAddBusiness }) => {
  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const { currency, billingId, annualBilling, appsumoValue } = useSelector(getActiveOrganization);

  const { monthlyPrices, annualPrices } = useSelector(getPricesData);

  const prices = annualBilling ? annualPrices : monthlyPrices;

  const regularPrice = useMemo(() => {
    if (!billingId) return null;
    try {
      const price = prices.regular[currency] || prices.regular[Constants.DEFAULT_CURRENCY];

      return Utils.toMoneyString(
        price,
        prices.regular[currency] ? currency : Constants.DEFAULT_CURRENCY,
        price % 1 ? 1 : 0
      );
    } catch (error) {
      return null;
    }
  }, [currency, billingId, prices]);

  const roboticPrice = useMemo(() => {
    if (!billingId) return null;
    try {
      const price = prices.robotic[currency] || prices.robotic[Constants.DEFAULT_CURRENCY];

      return Utils.toMoneyString(
        price,
        prices.robotic[currency] ? currency : Constants.DEFAULT_CURRENCY,
        price % 1 ? 1 : 0
      );
    } catch (error) {
      return null;
    }
  }, [currency, billingId, prices]);

  const [fetching, setFetching] = useState(false);

  const pricePeriodLabel = useMemo(() => {
    return (annualBilling ? uiTexts.year : uiTexts.month).toLowerCase();
  }, [uiTexts, annualBilling]);

  const [
    ,
    regularWorkflowFeatureA,
    regularWorkflowFeatureB,
    regularWorkflowFeatureC,
    regularWorkflowFeatureD,
    regularWorkflowFeatureE
  ] = messages.onboardingRegularWorkflow;

  const [
    ,
    rpaWorkflowFeatureA,
    rpaWorkflowFeatureB,
    rpaWorkflowFeatureC,
    rpaWorkflowFeatureD,
    rpaWorkflowFeatureE,
    rpaWorkflowFeatureF,
    rpaWorkflowFeatureG,
    rpaWorkflowFeatureH
  ] = messages.onboardingRpaWorkflow;

  const handleButtonClick = useCallback(async({ currentTarget: { dataset: { service, rpa } } }) => {
    if (service === IntegrationServices.QUICK_BOOKS.value && rpa && !appsumoValue) {
      const modalResult = await dispatch(UiActions.showModal(
        messages.onboardingQboConnectWarning,
        uiTexts.notification,
        true,
        undefined,
        uiTexts.continue
      ));

      if (!modalResult) return;
    }
    if (service !== IntegrationServices.QUICK_BOOKS_DESKTOP.value) {
      setFetching(true);
    }

    onAddBusiness({ integrationService: service, rpaMode: rpa });
  }, [onAddBusiness, uiTexts, messages, appsumoValue, dispatch]);

  return (
    <div className={Css.addNewBusiness}>
      {!appsumoValue && <div className={Css.content}>
        <div className={Css.block}>
          <div className={Css.title}>
            <div>{uiTexts.dataEntryAutomationHub}</div>
            {regularPrice && <div className={Css.price}>{`${regularPrice} / ${pricePeriodLabel}`}</div>}
          </div>
          <div className={Css.items}>
            <div className={Css.item}>
              <img src={iconClockCounterClockwise} />
              <div>{regularWorkflowFeatureA}</div>
            </div>
            <div className={Css.item}>
              <img src={iconChatsCircle} />
              <div>{regularWorkflowFeatureB}</div>
            </div>
            <div className={Css.item}>
              <img src={iconCpu} />
              <div>{regularWorkflowFeatureC}</div>
            </div>
            <div className={Css.item}>
              <img src={iconDatabase} />
              <div>{regularWorkflowFeatureD}</div>
            </div>
            <div className={Css.item}>
              <img src={iconFileSearch} />
              <div>{regularWorkflowFeatureE}</div>
            </div>
          </div>
        </div>
        <div className={Css.block}>
          <div className={Css.title}>{uiTexts.selectConnection}</div>
          <div className={Css.buttons}>
            {IntegrationServices.getFullIntegratedList().map(({ value, label, logo }) => {
              return (
                <Button
                  outline
                  key={value}
                  disabled={!!appsumoValue}
                  data-service={value}
                  loading={fetching}
                  onClick={handleButtonClick}>
                  <img
                    key={value}
                    src={logo}
                    title={label}
                    alt={label} />
                  <span>{label}</span>
                </Button>
              );
            })}
          </div>
        </div>
      </div>}
      <div className={Css.content}>
        <div className={Css.block}>
          <div className={Css.title}>
            <div>{uiTexts.roboticAiBookkeeper}</div>
            {roboticPrice && <div className={Css.price}>{`${roboticPrice} / ${pricePeriodLabel}`}</div>}
          </div>
          <div className={Css.warning}>
            <Icons.WarningCircle />
            <span>{messages.postSignUpModalWarning}</span>
          </div>
          <div className={Css.items}>
            <div className={Css.item}>
              <Icons.Plus weight="bold" className={CommonCss.mediumDarkText} />
              <div><b>{rpaWorkflowFeatureH}</b></div>
            </div>
            <div className={Css.item}>
              <img src={iconCalculator} />
              <div>{rpaWorkflowFeatureA}</div>
            </div>
            <div className={Css.item}>
              <img src={iconRepeat} />
              <div>{rpaWorkflowFeatureB}</div>
            </div>
            <div className={Css.item}>
              <img src={iconCirclesFour} />
              <div>{rpaWorkflowFeatureC}</div>
            </div>
            <div className={Css.item}>
              <img src={iconChats} />
              <div>{rpaWorkflowFeatureD}</div>
            </div>
            <div className={Css.item}>
              <img src={iconChartLineUp} />
              <div>{rpaWorkflowFeatureE}</div>
            </div>
            <div className={Css.item}>
              <img src={iconAiRobot} />
              <div>{rpaWorkflowFeatureF}</div>
            </div>
            <div className={Css.item}>
              <img src={iconCpu} />
              <div>{rpaWorkflowFeatureG}</div>
            </div>
          </div>
        </div>
        <div className={Css.block}>
          <div className={Css.title}>{uiTexts.selectConnection}</div>
          <div className={Css.buttons}>
            {Utils.arraySort(IntegrationServices.getFullIntegratedList(), ({ availableForRpa }) => !!availableForRpa)
              .filter(({ offlineIntegration }) => !offlineIntegration)
              .map(({ value, label, logo, availableForRpa }) => {
                return (
                  <Button
                    outline
                    key={value}
                    disabled={!availableForRpa}
                    loading={fetching}
                    data-service={value}
                    data-rpa={availableForRpa}
                    onClick={handleButtonClick}>
                    <img
                      key={value}
                      src={logo}
                      title={label}
                      alt={label} />
                    <span>{label}</span>
                    {!availableForRpa && (
                      <span className={classNames(Css.badge, Css.soonBadge)}>{uiTexts.soon}</span>
                    )}
                  </Button>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AddNewBusiness);
