import CommonCss from "nlib/common/common.module.scss";

import Css from "nlib/windows/PairTransactionsWindow/style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { TableCell, TableRow } from "nlib/ui/Table";
import {
  checkSelectedBusinessHasWhoAsk,
  checkSelectedBusinessRpaMode
} from "selectors/businesses";
import { getAccountsData } from "selectors/accounts";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { getUserRole } from "selectors/user";
import { useSelector } from "react-redux";
import ActionCellContent from "nlib/common/ActionCellContent";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React, { useCallback, useMemo } from "react";
import TransactionsStatuses from "nlib/pages/TransactionsPage/TransactionsStatuses";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const { EMPTY_PLACEHOLDER, DATETIME_FORMATS } = Constants;

const { TRANSACTION_TYPES: { WITHDRAW } } = DataConstants;

const TransactionsTableRow = ({ transaction, documentData, onClose }) => {
  const {
    id: transactionId,
    type,
    status,
    amount,
    currency,
    accountId,
    usesItems,
    timestamp,
    description,
    account = {},
    address = {},
    extra = {}
  } = transaction;

  const accountsData = useSelector(getAccountsData);

  const { id: documentId, paidTransactions = [] } = documentData;

  const { uiTexts } = useSelector(getTextsData);

  const { businessOrganization } = useSelector(getActiveOrganization);

  const userRole = useSelector(getUserRole);

  const businessUser = UserRoles.checkIsBusiness(userRole);

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  const selectedBusinessHasWhoAsk = useSelector(checkSelectedBusinessHasWhoAsk);

  const { labelLangId, iconComponent: Icon } = useMemo(() => {
    return TransactionsStatuses.getStatusData([
      status,
      businessOrganization,
      rpaMode && !selectedBusinessHasWhoAsk,
      businessUser
    ]);
  }, [businessOrganization, businessUser, rpaMode, selectedBusinessHasWhoAsk, status]);

  const accountData = useMemo(() => {
    return Utils.arrayFindById(accountsData, accountId || account.id, {});
  }, [account, accountId, accountsData]);

  const extraValues = useMemo(() => {
    return Object.entries(extra)
      .map(([field, fieldValue]) => fieldValue && `${field}: ${fieldValue}`)
      .filter((extraText) => extraText)
      .join(", ");
  }, [extra]);

  const amountContent = useMemo(() => {
    return amount && Utils.toMoneyString(amount, currency);
  }, [amount, currency]);

  const paidTransaction = useMemo(() => {
    return paidTransactions.some(({ id }) => id === transactionId);
  }, [paidTransactions, transactionId]);

  const menuItems = useMemo(() => {
    return [
      paidTransaction
        ? {
          action: "unpair",
          icon: Icons.X,
          text: uiTexts.unpair,
          theme: "danger"
        }
        : {
          action: "pair",
          icon: Icons.Link,
          text: uiTexts.pair,
          theme: "primary",
          disabled: usesItems
        }
    ];
  }, [paidTransaction, usesItems, uiTexts]);

  const withdrawTransaction = type === WITHDRAW;

  const accountContent = accountData.name || accountData.accountNumber;

  const extraContent = `${extraValues.length ? `${extraValues}${description ? ", " : ""}` : ""}${description || ""}`;

  const handleActionClick = useCallback((action) => {
    switch (action) {
      case "pair":
        onClose({ transactionId, documentId });
        break;
      case "unpair":
        onClose({ transactionId, documentId, unpair: true });
        break;
      default:
        break;
    }
  }, [documentId, onClose, transactionId]);

  return (
    <TableRow
      className={classNames(Css.tableRow, paidTransactions.some(({ id }) => id === transactionId) && Css.highlighted)}>
      <TableCell className={classNames(Css.statusCell, Css[status])}>
        <div title={uiTexts[labelLangId]} data-status={status}><Icon /></div>
      </TableCell>
      <TableCell className={Css.accountCell}>
        {accountContent
          ? (
            <div title={accountContent}>
              <Icons.Bank />
              <span>{accountContent}</span>
            </div>
          )
          : EMPTY_PLACEHOLDER}
      </TableCell>
      <TableCell className={Css.amountCell}>
        <div
          title={amountContent}
          className={classNames(Css.amount, type && CommonCss.coloredNumber)}
          data-negative={withdrawTransaction ? "" : undefined}>
          {amountContent}
        </div>
      </TableCell>
      <TableCell className={Css.addressCell}>
        {address && address.name
          ? <div title={address.name}>
            <Icons.User />
            <span>{address.name}</span>
          </div>
          : <div>{Constants.EMPTY_PLACEHOLDER}</div>}
      </TableCell>
      <TableCell className={Css.timestampCell}>
        {timestamp ? moment.utc(timestamp).format(DATETIME_FORMATS.DATE_TEXT) : EMPTY_PLACEHOLDER}
      </TableCell>
      <TableCell className={Css.descriptionCell}>
        <div title={extraContent || ""}>{extraContent || EMPTY_PLACEHOLDER}</div>
      </TableCell>
      <TableCell className={classNames(Css.actionsCell, Css.tableBodyCell)}>
        <ActionCellContent
          menuItems={menuItems}
          onClick={handleActionClick} />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(TransactionsTableRow);
