import Css from "../../style.module.scss";

import { FormElement } from "nlib/common/Form";
import { checkSelectedBusinessRpaMode } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Checkbox from "nlib/ui/Checkbox";
import FormLabel from "nlib/common/FormLabel";
import IntegrationServices from "const/IntegrationServices";
import React from "react";

const OcrAiTab = ({ integrationService }) => {
  const { uiTexts } = useSelector(getTextsData);

  const quickBooksDesktopBusiness = integrationService === IntegrationServices.QUICK_BOOKS_DESKTOP.value;

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  return (
    <>
      <div className={Css.block}>
        <FormLabel>{uiTexts.documents}</FormLabel>
        <div className={Css.row}>
          <FormElement
            toggle
            name="advancedDocumentsWorkflow"
            disabled={quickBooksDesktopBusiness || rpaMode}
            element={Checkbox}>
            <span>{uiTexts.enableAdvancedWorkflow}</span>
          </FormElement>
        </div>
        <div className={Css.row}>
          <FormElement
            toggle
            name="recognizeDocumentLineItems"
            element={Checkbox}>
            <span>{uiTexts.recognizeLineItems}</span>
          </FormElement>
        </div>
      </div>
    </>
  );
};

export default React.memo(OcrAiTab);
