import React, { useCallback, useEffect, useMemo, useState } from "react";

import DatePickerContext from "nlib/ui/DatePicker/lib/DatePickerContext";
import DatePickerDays from "nlib/ui/DatePicker/lib/DatePickerDays";
import DatePickerMonths from "nlib/ui/DatePicker/lib/DatePickerMonths";
import DatePickerYears from "nlib/ui/DatePicker/lib/DatePickerYears";

const DAYS_VIEW = 0;

const MONTHS_VIEW = 1;

const YEARS_VIEW = 2;

const PAD_START = 2;

const getDataString = (parts) => parts.map((part) => String(part).padStart(PAD_START, "0")).join("-");

const DatePickerContent = (props) => {
  const {
    range,
    startDateObject,
    endDateObject,
    useOneMonth,
    onChange
  } = props;

  const [view, setView] = useState(DAYS_VIEW);

  const [nextView, setNextView] = useState(DAYS_VIEW);

  const [currentMonth, setCurrentMonth] = useState((startDateObject || new Date()).getMonth());

  const [currentYear, setCurrentYear] = useState((startDateObject || new Date()).getFullYear());

  const handleDaySelect = useCallback(({ year, month, date }) => {
    onChange(getDataString([year, month + 1, date]));
  }, [onChange]);

  const providedContext = useMemo(() => ({
    startDateObject,
    endDateObject,
    currentMonth,
    currentYear,
    setCurrentMonth,
    setCurrentYear,
    setDaysView: () => setNextView(DAYS_VIEW),
    setMonthsView: () => setNextView(MONTHS_VIEW),
    setYearsView: () => setNextView(YEARS_VIEW)
  }), [currentMonth, currentYear, startDateObject, endDateObject]);

  useEffect(() => setView(nextView), [nextView]);

  return (
    <DatePickerContext.Provider value={providedContext}>
      {(view === YEARS_VIEW)
        ? (<DatePickerYears />)
        : ((view === MONTHS_VIEW)
          ? <DatePickerMonths {...props} />
          : <DatePickerDays {...props} onSelect={handleDaySelect} />)}
      {range && !useOneMonth && (
        <DatePickerContent
          {...props}
          range={false}
          secondView
          startDateObject={startDateObject}
          endDateObject={endDateObject}
          onChange={onChange} />
      )}
    </DatePickerContext.Provider>
  );
};

export default React.memo(DatePickerContent);
