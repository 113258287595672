import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import BrowserEvents from "const/BrowserEvents";
import DropDownContext from "nlib/ui/DropDown/DropDownContext";
import Portal from "nlib/ui/Portal";
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";
import useWindowResize from "hooks/useWindowResize";

const CONTENT_INDENT = 16;

const Content = (props) => {
  const {
    children,
    alignRight,
    modal,
    portal = modal,
    title,
    centered = true,
    className,
    ...restProps
  } = props;

  const { setOpened, containerRef } = useContext(DropDownContext);

  const dropdownRef = useRef();

  const [{
    right: overflowRight,
    top: overflowTop,
    bottom: overflowBottom
  }, setOverflow] = useState({ right: 0, top: 0, bottom: 0 });

  const style = useMemo(() => {
    const container = containerRef && containerRef.current;

    if (!container || modal) return {};

    const { innerHeight: windowHeight } = window;

    const { top, left, bottom, height, width } = container.getBoundingClientRect();

    // eslint-disable-next-line no-magic-numbers
    const onTop = top > (windowHeight + height) / 2;

    const maxHeight = onTop ? top - CONTENT_INDENT : windowHeight - bottom - CONTENT_INDENT;

    if (portal) {
      return {
        minWidth: width,
        top: onTop ? "auto" : bottom + overflowBottom,
        left: left - overflowRight,
        bottom: onTop ? windowHeight - top + overflowTop : "auto",
        maxHeight
      };
    }

    return {
      top: onTop ? "" : "100%",
      left: alignRight ? undefined : (overflowRight ? -overflowRight : 0),
      bottom: onTop ? "100%" : "",
      maxHeight
    };
  }, [containerRef, modal, portal, alignRight, overflowRight, overflowTop, overflowBottom]);

  const windowMouseDownHandler = useCallback((event) => {
    if (modal) return;

    let parent = event.target;

    // eslint-disable-next-line no-loops/no-loops
    while (parent) {
      if (parent.isSameNode(dropdownRef.current)
        || (containerRef && parent.isSameNode(containerRef.current))) return;
      parent = parent.parentNode;
    }
    setOpened(false);
  }, [modal, setOpened, containerRef]);

  const handleCloseClick = useCallback(() => {
    setOpened(false);
  }, [setOpened]);

  const windowResizeHandler = useCallback(() => {
    if (!Utils.checkIsTouchDevice()) setOpened(false);
  }, [setOpened]);

  useEffect(() => {
    const { MOUSE_DOWN, MOUSE_UP } = BrowserEvents;

    const eventName = Utils.checkIsTouchDevice() ? MOUSE_DOWN : MOUSE_UP;

    window.addEventListener(eventName, windowMouseDownHandler);

    return () => {
      window.removeEventListener(eventName, windowMouseDownHandler);
    };
  }, [setOpened, windowMouseDownHandler]);

  useEffect(() => {
    const { width: dropdownWidth, top: dropdownTop, height: dropdownHeight } = dropdownRef.current.getBoundingClientRect();

    const { left } = containerRef.current.getBoundingClientRect();

    const contentIndent = Utils.checkIsTouchDevice() ? 0 : CONTENT_INDENT;

    setOverflow({
      right: Math.max(left + dropdownWidth + contentIndent - window.innerWidth, 0),
      top: Math.min(dropdownTop, 0),
      bottom: Math.min(window.innerHeight - dropdownTop - dropdownHeight, 0)
    });
  }, [containerRef, modal, windowMouseDownHandler]);

  useWindowResize(windowResizeHandler);

  const Wrapper = portal ? Portal : Fragment;

  return (
    <Wrapper>
      {modal && (
        <div className={Css.dropDownOverlay} />
      )}
      <div
        {...restProps}
        className={classNames(
          Css.dropDownContent,
          alignRight && Css.alignRight,
          modal && Css.modal,
          portal ? Css.portal : Css.relative,
          centered && Css.centered,
          className
        )}
        style={style}
        ref={dropdownRef}>
        {modal && (
          <div className={Css.header}>
            <div className={Css.title}>{title}</div>
            <div className={Css.close} onClick={handleCloseClick}>
              <Icons.X />
            </div>
          </div>
        )}
        {children}
      </div>
    </Wrapper>
  );
};

const DropDownContent = ({ disabled, onShow, ...restProps }) => {
  const { opened } = useContext(DropDownContext);

  useEffect(() => {
    if (opened && !disabled && onShow) onShow();
  }, [disabled, onShow, opened]);

  if (!opened || disabled) return null;

  return (
    <Content {...restProps} />
  );
};

export default React.memo(DropDownContent);
