import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import quickBooksDesktop from "assets/quickBooksDesktop.svg";

import * as Icons from "@phosphor-icons/react";
import { Button } from "shards-react";
import { checkIntegrationsFetching } from "selectors/integrations";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Constants from "const/Constants";
import IntegrationServices from "const/IntegrationServices";
import IntegrationsActions from "actions/IntegrationsActions";
import Modal, { ModalFooter } from "nlib/ui/Modal";
import React, { useCallback } from "react";

const QuickBooksDesktopIcon = () => <img className={Css.img} src={quickBooksDesktop} />;

const AddQuickBooksDesktopWindow = ({ onClose, ...restProps }) => {
  const dispatch = useDispatch();

  const fetchingData = useSelector(checkIntegrationsFetching);

  const { uiTexts, messages } = useSelector(getTextsData);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleDownloadButtonClick = useCallback(() => {
    dispatch(IntegrationsActions.downloadQbwcFile());
  }, [dispatch]);

  const [
    quickBooksWebConnectorDescriptionA,
    quickBooksWebConnectorDescriptionB,
    quickBooksWebConnectorDescriptionC
  ] = messages.quickBooksWebConnectorDescription;

  const guideUrl = `${Constants.QBWC_DEVELOPER_PORTAL_URL}${Constants.QBWC_INSTALLATION_GUIDE_PATH}`;

  return (
    <Modal
      {...restProps}
      className={Css.addQuickBooksDesktopWindow}
      title={IntegrationServices.QUICK_BOOKS_DESKTOP.label}
      iconComponent={QuickBooksDesktopIcon}
      onClose={handleClose}>
      <div className={Css.text}>
        <Icons.Info className={CommonCss.highlightText} />
        <span>{`${quickBooksWebConnectorDescriptionA}.`}</span>
      </div>
      <div className={Css.text}>
        <span>{`${quickBooksWebConnectorDescriptionB}.`}</span>
      </div>
      <div className={Css.text}>
        <b>
          <span>{`${quickBooksWebConnectorDescriptionC} `}</span>
          <a href={guideUrl} target="_blank">{uiTexts.here.toLowerCase()}</a>
        </b>
      </div>
      <ModalFooter>
        <Button theme="success" disabled={fetchingData} onClick={handleDownloadButtonClick}>
          <Icons.DownloadSimple />
          <span>{uiTexts.downloadYourQbwcProfile}</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(AddQuickBooksDesktopWindow);
