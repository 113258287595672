import Css from "./style.module.scss";

import React from "react";

const DropDownMenuDivider = ({ label }) => {
  return (
    <div className={Css.dropDownMenuDivider}>
      {!!label && <div className={Css.label}>{label}</div>}
    </div>
  );
};

export default React.memo(DropDownMenuDivider);
