import Css from "./style.module.scss";

import React from "react";
import classNames from "classnames";

const PLACES = {
  top: "top",
  bottom: "bottom",
  right: "right",
  left: "left"
};

const STYLES = {
  [PLACES.top]: Css.top,
  [PLACES.bottom]: Css.bottom,
  [PLACES.right]: Css.right,
  [PLACES.left]: Css.left
};

const Tooltip = ({ className, opened = false, place = PLACES.top, simple, children, ...restProps }) => {
  if (!opened) return null;

  return (
    <div className={classNames(Css.tooltip, simple && Css.simple, STYLES[place], className)} {...restProps}>
      {children}
    </div>
  );
};

export default React.memo(Tooltip);
