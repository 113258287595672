import Css from "./style.module.scss";

import DropDownContext from "./DropDownContext";
import React, { useMemo, useRef, useState } from "react";
import classNames from "classnames";

const DropDown = (props) => {
  const [openedState, setOpenedState] = useState(false);

  const innerRef = useRef();

  const {
    children,
    className,
    opened = openedState,
    containerRef = innerRef,
    disabled,
    readOnly,
    setOpened = setOpenedState,
    ...restProps
  } = props;

  const contextValue = useMemo(() =>
    ({ opened, disabled, readOnly, containerRef, setOpened }),
  [opened, disabled, containerRef, readOnly, setOpened]);

  return (
    <DropDownContext.Provider value={contextValue}>
      <div
        {...restProps}
        ref={containerRef}
        className={classNames(Css.dropDown, className)}>
        {children}
      </div>
    </DropDownContext.Provider>
  );
};

export { default as DropDownToggle } from "./lib/DropDownToggle";
export { default as DropDownCaret } from "./lib/DropDownCaret";
export { default as DropDownContent } from "./lib/DropDownContent";
export { default as DropDownMenuItem } from "./lib/DropDownMenuItem";

export default React.memo(DropDown);
