import Css from "../../style.module.scss";

import { FormElement } from "nlib/common/Form";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Checkbox from "nlib/ui/Checkbox";
import FormLabel from "nlib/common/FormLabel";
import React from "react";

const UncategorizedTab = () => {
  const { uiTexts } = useSelector(getTextsData);

  return (
    <>
      <div className={Css.block}>
        <FormLabel>{uiTexts.transactions}</FormLabel>
        <div className={Css.row}>
          <FormElement
            toggle
            name="allowApproveWithoutPayee"
            element={Checkbox}>
            <span>{uiTexts.allowApproveWithoutPayee}</span>
          </FormElement>
        </div>
      </div>
    </>
  );
};

export default React.memo(UncategorizedTab);
