import PricingPlans from "const/PricingPlans";

class Features {
  static WHITE_LABEL = "whiteLabel";

  static EDIT_BUSINESS_SETTINGS = "editBusinessSettings";

  static DOCUMENTS_IMPORT = "documentsImport";

  static DOCUMENTS_INBOX = "documentsInbox";

  static TASKS = "tasks";

  static REQUIREMENTS = {
    [Features.WHITE_LABEL]: PricingPlans.PREMIUM,
    [Features.EDIT_BUSINESS_SETTINGS]: PricingPlans.SMART,
    [Features.DOCUMENTS_IMPORT]: PricingPlans.SMART,
    [Features.DOCUMENTS_INBOX]: PricingPlans.SMART,
    [Features.TASKS]: PricingPlans.SMART
  };

  static checkIsAllowed(pricingPlan, feature) {
    return !!(pricingPlan && feature) && (
      PricingPlans.PLANS.indexOf(pricingPlan) >= PricingPlans.PLANS.indexOf(Features.REQUIREMENTS[feature])
    );
  }
}

export default Features;
