import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { NavLink } from "react-router-dom";
import { checkAccountsAvailable } from "selectors/accounts";
import { checkFetchingData } from "selectors/data";
import { checkIsBusinessUser } from "selectors/user";
import {
  checkSelectedBusinessHasBusinessUsers,
  getGlobalStats,
  getSelectedBusinessId
} from "selectors/businesses";
import { getActiveOrganization } from "selectors/organizations";
import { getLinkExpirationsInfoData } from "selectors/institutions";
import { getTextsData } from "selectors/texts";
import { getUploadingArchiveFilesCount } from "selectors/archive";
import { getUploadingDocumentsCount } from "selectors/documents";
import { useSelector } from "react-redux";
import Badge from "nlib/ui/Badge";
import Countries from "const/Countries";
import Features from "const/Features";
import Pages from "nlib/pages/Pages";
import React, { useMemo } from "react";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";
import useAllowedFeature from "hooks/useAllowedFeature";

const { TRANSACTIONS, DOCUMENTS, TASKS, DASHBOARD, CONTACTS, ACTIVITY, AUDIT, ACCOUNTS, SETTINGS, ARCHIVE } = UiRoutes;

const FULL_LIST_MIN_LENGTH = 5;

const Navbar = () => {
  const { businessOrganization } = useSelector(getActiveOrganization);

  const selectedBusinessHasBusinessUsers = useSelector(checkSelectedBusinessHasBusinessUsers);

  const checkIsAllowed = useAllowedFeature();

  const { uiTexts } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const globalStatsData = useSelector(getGlobalStats);

  const accountsAvailable = useSelector(checkAccountsAvailable);

  const documentsCurrentUploadCount = useSelector(getUploadingDocumentsCount);

  const archiveFilesCurrentUploadCount = useSelector(getUploadingArchiveFilesCount);

  const fetchingData = useSelector(checkFetchingData);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const czCountry = activeOrganization.countryCode === Countries.CZ;

  const businessUser = useSelector(checkIsBusinessUser);

  const linkExpirationsInfoData = useSelector(getLinkExpirationsInfoData);

  const filteredLinkExpirationsInfoData = useMemo(() => {
    return linkExpirationsInfoData.filter(({ businessId }) => businessId === selectedBusinessId);
  }, [selectedBusinessId, linkExpirationsInfoData]);

  const pagesList = useMemo(() => {
    return Object
      .values(Pages.getPageData(null, businessUser))
      .filter(({ value }) => {
        switch (value) {
          case TASKS:
            return checkIsAllowed(Features.TASKS)
              && (!businessOrganization || selectedBusinessHasBusinessUsers);
          case TRANSACTIONS:
            return !czCountry;
          case ACCOUNTS:
            return czCountry;
          case DASHBOARD:
            return !businessUser;
          case CONTACTS:
          case ACTIVITY:
          case AUDIT:
          case SETTINGS:
            return false;
          default:
            return true;
        }
      });
  }, [
    businessOrganization,
    businessUser,
    checkIsAllowed,
    czCountry,
    selectedBusinessHasBusinessUsers
  ]);

  return (
    <div
      className={classNames(
        Css.navbar,
        pagesList.length >= FULL_LIST_MIN_LENGTH && Css.fullWidth,
        czCountry && Css.czCountry
      )}>
      <div className={Css.container}>
        {pagesList.map((pageData) => {
          const {
            value: route,
            disabled,
            titleLangId,
            urlParameters,
            statsNotificationProp,
            statsNotificationUrlParameters,
            iconComponent
          } = pageData;

          const statsNotification = Utils.getPropertyByPath(globalStatsData, statsNotificationProp);

          const searchString = statsNotification
            && statsNotificationUrlParameters
            && (route !== DOCUMENTS)
            ? Utils.objectToQueryString({ ...urlParameters, ...statsNotificationUrlParameters }) : "";

          const uploading = (route === DOCUMENTS && documentsCurrentUploadCount)
            || (route === ARCHIVE && archiveFilesCurrentUploadCount);

          const badgeContent = uploading || statsNotification || (route === ACCOUNTS && filteredLinkExpirationsInfoData.length);

          const Icon = route === ACCOUNTS && filteredLinkExpirationsInfoData.length ? Icons.Warning : iconComponent;

          return (
            <NavLink
              key={route}
              disabled={disabled || fetchingData || (route === TRANSACTIONS && !accountsAvailable)}
              className={classNames({
                [Css.navItem]: true,
                [Css.uploading]: uploading,
                [Css.danger]: route === ACCOUNTS && filteredLinkExpirationsInfoData.length
              })}
              activeClassName={Css.active}
              to={`/${selectedBusinessId}${route}${searchString ? `?${searchString}` : ""}`}>
              <div className={Css.icon}>
                {uploading ? <Icons.CloudArrowUp data-warning /> : <Icon />}
                {!!badgeContent && (
                  <Badge counter className={Css.notification}>
                    {badgeContent}
                  </Badge>
                )}
              </div>
              <div className={Css.title}>{uiTexts[titleLangId]}</div>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(Navbar);
