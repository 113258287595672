import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AddNewBusiness from "nlib/common/AddNewBusiness";
import Modal from "nlib/ui/Modal";
import React, { useCallback } from "react";

const AddQuickBooksDesktopWindow = ({ onClose, ...restProps }) => {
  const { uiTexts } = useSelector(getTextsData);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleAddBusiness = useCallback((result) => {
    onClose(result);
  }, [onClose]);

  return (
    <Modal
      {...restProps}
      className={Css.addQuickBooksDesktopWindow}
      dialogClassName={Css.dialog}
      contentClassName={Css.content}
      title={uiTexts.connectNewBussiness}
      iconComponent={Icons.Briefcase}
      onClose={handleClose}>
      <AddNewBusiness onAddBusiness={handleAddBusiness} />
    </Modal>
  );
};

export default React.memo(AddQuickBooksDesktopWindow);
