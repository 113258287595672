import Css from "./style.module.scss";

import React, { useCallback } from "react";
import classNames from "classnames";

const DatePickerDay = (props) => {
  const {
    item: {
      date,
      month,
      year,
      muted,
      selected,
      rangeEnd,
      disabled,
      highlighted
    },
    onSelect
  } = props;

  const handleClick = useCallback(() => {
    if (disabled || muted) return;
    onSelect({ year, month, date });
  }, [date, disabled, month, muted, year, onSelect]);

  return (
    <div
      className={classNames(Css.datePickerDay, muted
        ? Css.muted
        : {
          [Css.selected]: selected,
          [Css.disabled]: disabled,
          [Css.rangeEnd]: rangeEnd,
          [Css.highlighted]: highlighted
        })}
      onClick={handleClick}>
      {muted ? null : date}
    </div>
  );
};

export default React.memo(DatePickerDay);
